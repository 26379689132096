import axios from "axios";

const formData = new FormData();

formData.append("username", "out_user");
formData.append("password", "Qwerty+1243^shga");

export const getToken = async () => {
  try {
    const res = await axios({
      method: "post",
      url: "https://stock.general-trucks.ru/token",
      timeout: 20000,
      data: formData,
      mode: "no-cors",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const token = res.data.access_token;
    general_service_axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    // console.log(`Токен: ${token}`)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.response?.data.errText, "error");
    } else if (error instanceof Error) {
      console.log(error.message);
    }
  }
};

const general_service_axios = axios.create({
  baseURL: "https://stock.general-trucks.ru",
  timeout: 20000,
});

const armor_back_axios = axios.create({
  baseURL: "https://back.armor-oil.ru",
  timeout: 20000,
});

export class ArmorService {
  async sendRequest(request) {
    try {
      const response = await armor_back_axios.post("/api/request/", {
        request,
      });
      return response.data.results;
    } catch (error) {
      console.log(error.toJSON());
    }
  }
}

export class GeneralService {
  async getResource(url, params) {
    try {
      const token = await getToken();
      const response = await general_service_axios.get(url, { params });
      return response.data.results;
    } catch (error) {
      console.log(error.toJSON());
    }
  }
  getAllCategories() {
    const params = {
      status: 1,
      published: true,
      published_count_gte: 1,
      ordering: "kod",
      roditel_id: 112,
    };
    return this.getResource("api/directory/groups/", params);
  }
  getProducts(categoryID) {
    const params = {
      limit: 10000,
      status: 1,
      offset: 0,
      published: true,
      ordering: "title_store",
      groups_store_exact: categoryID,
    };
    return this.getResource("/api/directory/ma/", params);
  }
  getProduct(product_id) {
    const params = {
      id: product_id,
    };
    return this.getResource("/api/directory/ma/", params);
  }
  getProductsByFilter(filter) {
    const params = {
      f_eshop: filter,
      groups_store: "112",
    };
    return this.getResource("/api/directory/ma/", params);
  }
}
